(function(RS){
  "use strict";

  RS.Views.ContactUsView = RS.Views.BaseView.extend({
	el: '#contactus-container',

    initialize: function(options){
      this.form = this.$el.find("#contactus-form").parsley();
    },

    render: function(){
    	var $PhoneCountryCodeSelect = this.$el.find("[name=PhoneCode]");
    	var $PhoneCountryCode = this.$el.find("#select-menu-PhoneCode-contactus .dropdown-menu");
    	var $CountrySelect = this.$el.find("[name=Country]");
    	var $Country = this.$el.find("#select-menu-Country-contactus .dropdown-menu");
    	var $PropertySelect = this.$el.find("[name=Property]");
    	var $Property = this.$el.find("#select-menu-Property-contactus .dropdown-menu");

    	// reset the above lists
    	$PhoneCountryCodeSelect.find("option:not(:first)").remove();
    	$PhoneCountryCode.find("li:not(:first)").remove();
    	$CountrySelect.find("option:not(:first)").remove();
    	$Country.find("li:not(:first)").remove();
    	$PropertySelect.find("option:not(:first)").remove();
    	$Property.find("li:not(:first)").remove();

    	$.each(RS.countries.get('CallingCodes'), function(idx, c) {
    		if (c.CallingCode && c.Name) {
                $PhoneCountryCodeSelect.append("<option value='"+c.CallingCode+"'>"+c.Name+" ("+c.CallingCode+")"+"</option>");
                $PhoneCountryCode.append("<li><a data-val='"+c.CallingCode+"'>"+c.Name+" ("+c.CallingCode+")"+"</a></li>");
    		}
    	});

    	$.each(RS.countries.get('Country'), function(idx, c) {
                $CountrySelect.append("<option value='"+c+"'>"+c+"</option>");
                $Country.append("<li><a data-val='"+c+"'>"+c+"</a></li>");
    	});

    	RS.propertyCollection.each(function(item) {
            $PropertySelect.append("<option value='"+item.get('PropertyCode')+"'>"+item.get('PropertyName')+"</option>");
            $Property.append("<li><a data-val='"+item.get('PropertyCode')+"'>"+item.get('PropertyName')+"</a></li>");
    	});
    	$PhoneCountryCodeSelect.combobox();
    	$CountrySelect.combobox();
        this.delegateEvents( this.events );
    },
    events: {
      'click .gra-dropdown-select .dropdown-menu>li>a': 'setGRADropDown',
      'change .gra-dropdown-select select': 'setGRADropDown',
      'change .gra-search-select select': 'setGRASearchSelect',
      'click #contactus-form button[type=submit]': 'contactFormSubmit',
    },

    show: function(){
      this.render();
      this.resetForm();
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },

    resetForm: function() {
      var $form = this.$el.find("#contactus-form");
      $form.find("textarea").val('');
      if (RS.curUser && RS.curUser.has("ID")) {
          this.formReset($form);
    	  var names = [];
          if (RS.curUser.has("FirstName")) { names.push(RS.curUser.get("FirstName")); }
          if (RS.curUser.has("LastName")) { names.push(RS.curUser.get("LastName")); }
          $form.find("[name=Name]").val(names.join(' '));
          if (RS.curUser.has("Email")) {
              $form.find("[name=EmailAddress]").val(RS.curUser.get("Email"));
          }
          if (RS.cardID && RS.cards && RS.cards.get(RS.cardID) && RS.cards.get(RS.cardID).has("LinkedCardNumber")) {
              $form.find("[name=RewardCardNumber]").val(RS.cards.get(RS.cardID).get("LinkedCardNumber"));
          }
          if (RS.curUser.has("PhoneNumber")) {
              $form.find("[name=ContactNumber]").val(RS.curUser.get("PhoneNumber"));
          }
          if (RS.curUser.has("PhoneCountryCode")) {
              $form.find("[name=PhoneCode]").val(RS.curUser.get("PhoneCountryCode")).change();
          }
          if (RS.curUser.has("CountryOfResidence")) {
              $form.find("[name=Country]").val(RS.curUser.get("CountryOfResidence")).change();
          }
          $form.find("[name=Property]").val((RS.cardID && RS.cards.get(RS.cardID)) ? RS.cards.get(RS.cardID).get("PropertyCode") : '').change();
      } else {
          $form.find("input").val('');
          $form.find("[name=PhoneCode]").val('').change();
          $form.find("[name=Country]").val('').change();
          $form.find("[name=Property]").val('').change();
          this.formReset($form);
      }
    },

    contactFormSubmit: function(e){
      e.preventDefault();

      if (!this.form.validate()) {
        return;
      }
      var _this = this;

      var vals = this.$el.find("#contactus-form").serializeArray();
      var input = {};
      $.each(vals, function(i, v) {
    	  input[v.name] = v.value;
      });
      var contactus = new RS.Models.ContactUs();
      contactus.save(input).done(function(data) {
    	  RS.alert({message:window.wd.js_contactus_success_msg});
    	  _this.resetForm();
      }).fail(function(failed) {
/*      if (failed.responseJSON.Message) {
            errorInfo="<p>"+failed.responseJSON.Message+"</p>";
        }*/
        var modelState = failed.responseJSON.ModelState, errorInfo = '';
        if (modelState ) {
           $.each(modelState, function(k, v) {
    			  _.each(v,function(i){
    			    errorInfo += "<p>" + i + "</p>";
    			  });            
    		  });
           // errorInfo += "<p>"+failed.responseJSON.ModelState.ErrorMessage[0]+"</p>";
        }
        else {
        	errorInfo=failed.responseJSON.Message || wd.js_default_error_code;
        }
  	    RS.alert({message:errorInfo});
      });
    },

  });
})(window.Redso);
