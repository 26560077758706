(function (RS) {
    'use strict';

    RS.Collections.Properties = RS.Collections.GRA.extend({
        model: RS.Models.Property,

        //url: '/apis/get_brands',
        url: RS.apiUrl + '/IData/GetProperties',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });

    RS.Collections.PromoLines = RS.Collections.GRA.extend({
        model: RS.Models.PromoLine,


        url: RS.apiUrl + '/IPromos/GetPromotionLines',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });

})(window.Redso);