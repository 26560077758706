(function(RS){
  "use strict";
	var static_title = ' - Genting Rewards Alliance';
	var metadefault = {title:'Genting Rewards Alliance: Start redeeming offers today',desc:'Exclusive rewards such as hotel rooms, dining vouchers and many more. Redeem with your points now or become a member today!'};
	var metamap_static = {
				'about':{title: 'About Us'+ static_title, desc: 'Genting Rewards Alliance is a loyalty program for Genting Rewards members. We deliver an array of deals, redeemable with Genting Rewards Points and Star Points'},
			  'tutorial':{title: 'How to tutorial'+ static_title, desc: 'Learn how to register, link Rewards card, redeem and earn Genting Rewards and Star Points '},
				'contactus':{title: 'Contact Us'+ static_title, desc: 'Contact Genting Rewards Alliance customer support here. Submit a request form.'},
			  'faq':{title: 'Frequently Asked Questions'+ static_title, desc: 'Find answers to the questions about registration, redemption, your rewards card and account and start redeeming today'},
				'privacy':{title: 'Privacy Policy'+ static_title, desc: 'Read up on our privacy policy here. Genting Rewards Alliance privacy statement. Learn how GRA collects, holds, uses and processes data'},
			} ;
  RS.metacatdefault = {title: ' Rewards - Genting Rewards Alliance',desc_1:'Explore our attractive ', desc_2:' rewards. Redeem with your Genting Points today!'};
  // Define your library strictly...
  RS.Views.App = Backbone.View.extend({
	AppInit: function(options) {
      RS.FBUser =  {};

      RS.alert = function(options) {
    	if (!options) { options = {}; }
    	if (!options.title) { options.title = ''; }
    	if (!options.message) { options.message = ''; }
        $('#alert-modal .modal-title').html(options.title);
        $('#alert-modal .message').html(options.message);
        /*
        if (options.dismissable==false) {
          $('#alert-modal .close').hide();
        } else {
          $('#alert-modal .close').show();
        }
        */
        $('#alert-modal').modal("show");
    	if (options.callback) {
    		$('#alert-modal').off('hidden.bs.modal').on('hidden.bs.modal', options.callback);
    	} else {
    		$('#alert-modal').off('hidden.bs.modal');
    	}
      };

      //init facebook
	  this.on('fb.init', function(e) {
		var _this = this;
		/*
        FB.getLoginStatus(function(response) {
            if (typeof(_this.FBStatusChangeCallback) != 'undefined') {
                _this.FBStatusChangeCallback(response);
            }
        });
        */
	  });
      window.fbAsyncInit = function() {
        FB.init({
          appId      : '772933439397468',
          xfbml      : true,
          version    : 'v2.3'
        });

        // Trigger an event so that other views can know when the FB SDK is loaded.
        this.trigger('fb.init');
      }.bind(this);

      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
	},

	bindEvents: function() {
		$(".app-link").off().click(function(e) {
			e.preventDefault();
			if (navigator.platform == 'Android') {
				var win = window.open("https://play.google.com/store/apps/details?id=com.beyondsix&hl=en", '_blank');
				//win.focus();
			} else if ($.inArray(navigator.platform, ['iPhone', 'iPod', 'iPad', 'iPhone Simulator', 'iPod Simulator', 'iPad Simulator']) > -1) {
				window.open("https://itunes.apple.com/my/app/gentingrewards/id1015828153?mt=8&ign-mpt=uo%3D4&utm_source=itunesstore&utm_medium=website&utm_campaign=graapp", '_blank');
			} else if ($(this).hasClass("playstore")) {
				window.open("https://play.google.com/store/apps/details?id=com.rsl.gra&hl=en&utm_source=googleplaystore&utm_medium=website&utm_campaign=graapp", '_blank');
			} else if ($(this).hasClass("appstore")) {
				window.open("https://itunes.apple.com/my/app/gentingrewards/id1015828153?mt=8&ign-mpt=uo%3D4&utm_source=itunesstore&utm_medium=website&utm_campaign=graapp", '_blank');
			}
		});
	},

    'el': 'body',
    'viewName': '',

    initialize: function(){
        this.AppInit();
        this.bindEvents();

        RS.navbarView = new RS.Views.NavbarView({
            el: 'nav.navbar-fixed-top',
        });
        var rightMenuView = new RS.Views.RightMenuView({
              el: '#right-menu',
        });
        var SubscribeView = new RS.Views.SubscribeView({
          el:'footer .subscribe-view',
        });
        var homeView = new RS.Views.HomeView({
            el: '#home-container',
            searchdata: {
                categoryID: 0,
                pageSize: 6,
                page: 1,
            }
        });

        var promotionView = new RS.Views.PromotionView({
          el:'#promotion-detail-container',
        });

        var loginView = new RS.Views.LoginView({
          el: '#login-container',
        });

        var registerView = new RS.Views.RegisterView({
          el: '#register-container',
        });

        var forgetpwdView = new RS.Views.ForgetPWDView({
          el: '#forgetpwd-container',
        });

        var meView = new RS.Views.MeView({
        });

        var meEditView = new RS.Views.MeEditView({
        });

        var contactUsView = new RS.Views.ContactUsView({
        });

        var tutorialView = new RS.Views.TutorialView({
        });

        var faqView = new RS.Views.FAQView({
        });

        var aboutView = new RS.Views.AboutView({
        });

        var privacyView = new RS.Views.PrivacyView({
        });

        var getAppView = new RS.Views.GetAppView({
        });

        var resetpwdView = new RS.Views.ResetPWDView({
          el: '#resetpwd-container',
        });

        var verifyacctView = new RS.Views.VerifyAccountView({
          el: '#verifyacct-container',
        });

        var contestsView = new RS.Views.ContestsView({
          el:'#contests-container'
        });
      this.views = {
        home: homeView,
        login: loginView,
        register: registerView,
        forgetpwd: forgetpwdView,
        me: meView,
        mecards: meView,
        mefavourites: meView,
        mevouchers: meView,
        meedit: meEditView,
        mecardsedit: meEditView,
        mefavouritesedit: meEditView,
        mevouchersedit: meEditView,
        promotion:promotionView,
        contactus:contactUsView,
        about:aboutView,
        howtoredeem:tutorialView,
        howtoearn:tutorialView,
        howtolinkcard:tutorialView,
        tnc:tutorialView,
        faq:faqView,
        tutorial:tutorialView,
        privacy:privacyView,
        resetpwd:resetpwdView,
        verifyacct:verifyacctView,
        getapp:getAppView,
        contests:contestsView,
      };
    },
	metadataHdl:function(args){
		var viewName = args[0];
		console.log('arguments');
		console.log(arguments);
		console.log(viewName);
		  
    // _.each(metamap_cat,function(arr){
    // 	arr.title = arr.name + ' - Genting Rewards Alliance';
		//	arr.desc = arr.name
		var metainfo =metamap_static[viewName];
		if(metainfo){
			$('meta[name="title"]').prop('content',metainfo.title);
			$('meta[property="og:title"]').prop('content',metainfo.title);
			$('meta[name="description"]').prop('content',metainfo.desc);
			$('meta[property="og:description"]').prop('content',metainfo.desc);
		}else if(viewName=='home' && args[1] && args[1] =='category'){	 
			var cat = args[2];
			if(cat){
				var old_cat = cat.split('-');
				_.each(old_cat,function(v,i){ 
				if(v != 'and'){
						old_cat[i] = v[0].toUpperCase() + v.slice(1);
            if(old_cat[i+1] && old_cat[i+1] != 'and' ){
            	old_cat[i] += ',';
            }
        }
					console.log(old_cat[i]);
				});
				var new_cat = old_cat.join(' ');
				console.log('new_cat');
				console.log(new_cat);
				var cat_title = new_cat + RS.metacatdefault.title;
				var cat_desc = RS.metacatdefault.desc_1 + new_cat +RS.metacatdefault.desc_2;
				$('meta[name="title"]').prop('content',cat_title);
				$('meta[property="og:title"]').prop('content',cat_title);
				$('meta[name="description"]').prop('content',cat_desc);
				$('meta[property="og:description"]').prop('content',cat_desc); 
			}
		}else{
			$('meta[name="title"]').prop('content',metadefault.title);
			$('meta[property="og:title"]').prop('content',metadefault.title);
			$('meta[name="description"]').prop('content',metadefault.desc);
			$('meta[property="og:description"]').prop('content',metadefault.desc); 
		}
	},
    show: function(viewName){
			this.metadataHdl(arguments);
      $(".gra-combobox-dropdown:visible").hide();
      if (['home'].indexOf(viewName)<0) {
          $(window).scrollTop(0);
      }
      if ((!RS.access_token || !RS.curUser || $.isEmptyObject(RS.curUser.toJSON())) &&
    		  (viewName == 'me' || viewName == 'mecards' || viewName == 'mefavourites' || viewName == 'mevouchers' ||
    				  viewName == 'meedit' || viewName == 'mecardsedit' || viewName == 'mefavouritesedit' || viewName == 'mevouchersedit')
    		  ) {
    	RS.access_token = '';
        RS.login_redirect = window.location.hash;
        window.router.navigate('#!login',{trigger:true});
        return;
      }
      this.viewName = viewName;
      $('body').attr("data-page", viewName);
      if (viewName == '' || viewName == 'home' || viewName == 'promotion' ||
    		  viewName == 'about' || viewName == 'howtoredeem' || viewName == 'howtoearn' || viewName == 'tnc' ||
    		  viewName == 'faq' || viewName == 'tutorial' || viewName == 'privacy' || viewName == 'contactus'
    		  ) {
    	  $("#left-menu").css('display', '');
         $('#navbar-header-mobile').find('.btn-header.btn-left').css('visibility','visible');
      } else {
    	  $("#left-menu").css('display', 'none');
        $('#navbar-header-mobile').find('.btn-header.btn-left').css('visibility','hidden');
      }
      var args = Array.prototype.slice.call(arguments, 1);
      if (viewName && (viewName == 'me' || viewName == 'mecards' || viewName == 'mefavourites' || viewName == 'mevouchers')) {
        _.each(this.views, function(v, k){
          if (k == viewName){
            v.show.apply(v, args);
          } else if (k != 'me' && k != 'mecards' && k != 'mefavourites' && k != 'mevouchers'){
            v.hide();
          }
        });
      } else if (viewName && (viewName == 'meedit' || viewName == 'mecardsedit' || viewName == 'mefavouritesedit' || viewName == 'mevouchersedit')) {
        _.each(this.views, function(v, k){
          if (k == viewName){
            v.show.apply(v, args);
          } else if (k != 'meedit' && k != 'mecardsedit' && k != 'mefavouritesedit' && k != 'mevouchersedit'){
            v.hide();
          }
        });
      } else if (viewName && (viewName == 'tutorial' || viewName == 'howtoredeem' || viewName == 'howtoearn' || viewName == 'tnc'||viewName == 'howtolinkcard')) {
        _.each(this.views, function(v, k){
          if (k == viewName){
            v.show.apply(v, args);
          } else if (k != 'tutorial' && k != 'howtoredeem' && k != 'howtoearn' && k != 'tnc' && k != 'howtolinkcard'){
            v.hide();
          }
        });
      } else {
        _.each(this.views, function(v, k){
          if (k == viewName){
            v.show.apply(v, args);
          } else {
            v.hide();
          }
        });
      }

      setGTM();
      this.fixQueryString(viewName);
      this.hideMasterLoading();
    },
    
    setSelectedCard: function(cardID) {
    	if (cardID) {
    		$.get("/setselectedcard?cardID="+cardID);
    	}
    	RS.cardID = cardID;
    },
    hideMasterLoading: function() {
    	//if ($("#masterLoading").length > 0) {
        $("#masterLoading").fadeOut(function() {
            //$("#masterLoading").remove();
            $("html, body").css("overflow", "");
        });
    	//}
    },
    showMasterLoading: function() {
        $("#masterLoading").fadeIn(function() {
            //$("#masterLoading").remove();
            $("html, body").css("overflow", "hidden");
        });
    },
    fixQueryString: function(viewName){
        if(['', 'home', 'promotion'].indexOf(viewName)>-1){
          return;
        }
        var params = purl().data.param.query || {};
        if( 'currency' in params && 'sortby' in params){
            params = _.omit(params, 'currency', 'sortby');
            var searchUrl = $.param(params);
            searchUrl = searchUrl ? '?' + searchUrl : '';
            var url = window.location.origin + window.location.pathname + searchUrl + window.location.hash;
            var pushStateParam = {};
            pushStateParam[viewName] = url;
            window.history.pushState(pushStateParam, viewName, url);        
        }         
    },
  });

})(window.Redso);
