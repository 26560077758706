(function (RS) {
    "use strict";

    RS.Models.Country = RS.Models.GRA.extend({
        idAttribute: 'CountryCode',
        parse: function (resp, options) {
            if (RS.lang && resp.Name_zh && resp.Name_zh_tw) {
                if (RS.lang == 'en') {
                    resp.NameLang = resp.Name;
                }
                else if (RS.lang == 'zh-tw') {
                    resp.NameLang = resp.Name_zh_tw;
                }
                else {
                    resp.NameLang = resp.Name_zh;
                }
            }
            else {
                resp.NameLang = resp.Name;
            }

            return resp;
        }
    });

    RS.Models.State = RS.Models.GRA.extend({
        idAttribute: 'ID',
        parse: function (resp, options) {
            if (RS.lang && resp.Name_zh && resp.Name_zh_tw) {
                if (RS.lang == 'en') {
                    resp.NameLang = resp.Name;
                }
                else if (RS.lang == 'zh-tw') {
                    resp.NameLang = resp.Name_zh_tw;
                }
                else {
                    resp.NameLang = resp.Name_zh;
                }
            }
            else {
                resp.NameLang = resp.Name;
            }
            return resp;
        }
    });

    RS.Models.Geo = RS.Models.GRA.extend({
        url: RS.apiUrl + '/IData/GetGeoLocation',
        parse: function (resp, options) {
            if ($.isArray(resp)) {
                resp = resp[0];
            }
            if (RS.lang && resp.Name_zh && resp.Name_zh_tw) {
                if (RS.lang == 'en') {
                    resp.NameLang = resp.Name;
                }
                else if (RS.lang == 'zh-tw') {
                    resp.NameLang = resp.Name_zh_tw;
                }
                else {
                    resp.NameLang = resp.Name_zh;
                }
            }
            else {
                resp.NameLang = resp.Name;
            }
            return resp;
        }
    });


    RS.Models.DropDownList = RS.Models.GRA.extend({
        url: RS.apiUrl + '/IData/GetDropDownList?type=All',
        parse: function (resp, options) {
            return resp;
        }
    });

})(window.Redso);
