(function(RS){
  "use strict";

  RS.Views.FAQView = RS.Views.BaseView.extend({
	el: '#faq-container',

    initialize: function(options){
    },

    render: function(){
    },
    events: {
        'click .faq-nav .item': 'navItemClick',
        'click .sections>a.headerbtn': 'mobileItemClick',
    },

    navItemClick: function(e){
    	e.preventDefault();
    	var $thisitem = $(e.target), section = $thisitem.attr("data-section"), $navDesk = $('.faq-nav'), $sections = $('.sections');
    	if (!$thisitem.hasClass("active")) {
            this.itemClickHandler($thisitem, section, $navDesk, $sections);
            this.viewHandler(section);
    	}
    },

    mobileItemClick:function(e){
      e.preventDefault();
    	var $thisitem = $(e.target), section = $thisitem.attr("data-section"), $navDesk = $('.faq-nav'), $sections = $('.sections');
    	if (!$thisitem.hasClass("collapsed")) {
          this.itemClickHandler($thisitem, section, $navDesk, $sections);
          this.viewHandler(section);
    	}
      else{
          $thisitem.removeClass("collapsed").attr('aria-expanded','false');
          $sections.find(".section."+section).removeClass('in').css('height',0);
      }
    },

    itemClickHandler:function($thisitem,section,$navDesk,$sections){
       $navDesk.find(".item.active").removeClass("active");
       $navDesk.find('[data-section="'+ section +'"]').addClass('active');
       $sections.find('a.collapsed').removeClass("collapsed").attr('aria-expanded','false');
       $sections.find('[data-section="'+ section +'"]').addClass("collapsed").attr('aria-expanded','true');
    },
    
    viewHandler:function(section){
    	/*
    	if (section == 'overview') {
            window.router.navigate('#!faq/', {trigger: false});
    	} else if (section == 'howtoredeem') {
            window.router.navigate('#!faq/redeem', {trigger: false});
    	} else if (section == 'howtoearn') {
            window.router.navigate('#!faq/earn', {trigger: false});
    	} else {
            window.router.navigate('#!faq/tnc', {trigger: false});
    	}
    	*/
    		this.$el.find(".section").removeClass('in').css('height',0);

        var $targetSection = this.$el.find(".section."+section);
    		$targetSection.addClass('in').css('height','auto');
        if(ResponsiveBootstrapToolkit.is('xs')){
          $(window).scrollTop(0);
        }
    },

    show: function(){
      this.$el.find(".sections .section").removeClass('in');
      this.$el.find(".faq-nav .item.active").removeClass("active");
      var viewName = window.app.viewName;
      viewName = 'reg';
      var viewNameToSection = {
        'faq':'overview',
        'howtoredeem':'howtoredeem',
        'howtoearn':'howtoearn',
        'tnc':'tnc'
      };
      var section = viewNameToSection[viewName];
      section = 'reg';
      this.$el.find('.faq-nav .item[data-section="'+ section +'"]').addClass("active");
      this.$el.find('.sections .headerbtn[data-section="'+ section +'"]').addClass('collapsed').attr('aria-expanded','true');
      this.$el.find(".sections .section."+ section +"").addClass('in');
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },

  });
})(window.Redso);
