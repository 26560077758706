(function(RS){
  "use strict";

  RS.Models.User = RS.Models.GRA.extend({
    sync: function(method, model, options){
      // a quick work around
      // http://stackoverflow.com/questions/7644767/backbone-js-use-different-urls-for-model-save-and-fetch
      if (method=='read'){
        options.url = RS.apiUrl+'/IMembers/GetMemberInfo'; 
      } else if (method=='create'){
        options.url = RS.apiUrl+'/IMembers/Register'; 
      } else if (method=='update'){
        options.url = RS.apiUrl+'/IMembers/ChangeProfile'; 
      } else {
        options.url = ''; 
      }
      return RS.Models.GRA.prototype.sync(method, model, options);
    },

    fetchAll: function() {
      var _this = this;
      this.fetch().done(function(data) {
        var useraddr =  new RS.Models.UserAddr();
        useraddr.fetch().done(function(useraddrdata) {
        	if (useraddrdata && useraddrdata.length > 0) {
        		_this.set(useraddrdata[0]);
        	}
        }).always(function() {
    	  _this.trigger('fetchall');
        });
      }).fail(function(data) {
    	  _this.trigger('fetchall');
      });
    },

    parse: function(resp, options){
      if (resp && resp.JoinDate) {
    	  // TODO: may need to change the timezone
    	  resp.JoinDate = moment(resp.JoinDate);
      }
      resp.id = resp.ID;
      return resp;
    	/*
      var brand = resp;
      if (resp.meta){
        brand = resp.result || resp.brand;
        if (resp.products){
          brand.products = resp.products;
          _.each(brand.products, function(p){
            p = RS.Models.Product.prototype.parse(p);
          });
        }

        if (resp.categories){
          brand.categories = resp.categories;
        }
      }
      brand.lastupdatetime = moment(brand.lastupdatetime, 'x');
      brand.publishtime = moment(brand.publishtime, 'x');
      brand.isFeatured = brand.isFeatured && parseInt(brand.isFeatured, 10) != 0;
      return brand;
      */
    },

    //  Subscribe, unsubscribe, NewsletterStatus
    subscribe: function(data, type, options){
      if (type == 'self-subscribe') {
    	  var url = '/subscribe.php';
    	  if (window.location.pathname != "/") { url = window.location.pathname + "subscribe.php"; }
          return $.ajax({
            url: url,
            type: 'get',
            data: data,
          });
      } else {
          return $.ajax(_.defaults(options||{}, {
            url: RS.apiUrl+'/INewsLetter/' + type,
            type: 'get',
            data: {email: data},
          }));
      }
    },
  });

  RS.Models.LikePromotion = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IPromos/AddFavorite?promoID='+options.data.promoID; 
      options.type = 'POST';
      return RS.Models.GRA.prototype.sync(method, model, options);
    },
  });

  RS.Models.DislikePromotion = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IPromos/DeleteFavorite?promoID='+options.data.promoID; 
      options.type = 'DELETE';
      return RS.Models.GRA.prototype.sync(method, model, options);
    },
  });

  RS.Models.UserAddr = RS.Models.GRA.extend({
    sync: function(method, model, options){
      if (method=='read'){
        options.url = RS.apiUrl+'/IRedemptions/GetDefaultAddress'; 
      } else {
        options.url = RS.apiUrl+'/IRedemptions/SetAddress';
        options.type = 'PUT';
      }
      return RS.Models.GRA.prototype.sync(method, model, options);
    },
    parse:function(resp){
      return resp;
    }
  });

  RS.Models.UserPassword = RS.Models.GRA.extend({
    sync: function(method, model, options){
      if (method=='read'){
        options.url = RS.apiUrl+'/IMembers/ChangePassword'; 
      } else if (method=='create'){
        options.url = RS.apiUrl+'/IMembers/ChangePassword';
      } else {
        options.url = RS.apiUrl+'/IMembers/ChangePassword';
      }
      return RS.Models.GRA.prototype.sync(method, model, options);
    },
    parse:function(resp){
      return resp;
    }
  });

  RS.Models.UserForgetPassword = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IMembers/EmailForgotPassword',
  });

  RS.Models.UserEmail = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IMembers/ChangeEmail',
  });

  RS.Models.UserPhone = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IMembers/ChangePhoneNumber',
  });

  RS.Models.UserVerifyPhoneOTP = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IMembers/VerifyPhoneOTP',
  });

  RS.Models.UserLogin = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/IMemberAccount/Login',
  });

  RS.Models.UserVerifyLogin = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/IMemberAccount/VerifyLogin',
  });

  RS.Models.UserResetPassword = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IMembers/SetNewPassword',
  });

  RS.Models.UserVerifyAccount = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IMembers/VerifyCode?'+$.param(options.data); 
      options.type = 'POST';
      return RS.Models.GRA.prototype.sync(method, model, options);
    }
  });

})(window.Redso);
