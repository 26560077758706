(function(RS){
  "use strict";

  RS.Models.ContactUs = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IMisc/ContactUs'; 
      return RS.Models.GRA.prototype.sync(method, model, options);
    },

    parse: function(resp, options){
      if (resp && resp.JoinDate) {
    	  // TODO: may need to change the timezone
    	  resp.JoinDate = moment(resp.JoinDate);
      }
      resp.id = resp.ID;
      return resp;
    },

  });

})(window.Redso);
