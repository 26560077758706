(function(RS){
  "use strict";

  RS.Views.HomeView = RS.Views.BaseView.extend({
      defaults:{
      },
      initialize: function (options) {
          this.refresh = true;
          RS.promotionsCollection = new RS.Collections.Promotions();
          RS.promotionsView = new RS.Views.Promotions({
              collection: RS.promotionsCollection,
              el: '#promotions-view',
          });
          RS.featPromoCollection = new RS.Collections.FeatPromo();
          RS.featPromoView = new RS.Views.FeatPromo({
              collection: RS.featPromoCollection,
              el: '#featpromo-view',
          });
          RS.promotionsCollection.on('sync',function(){
             if(RS.promotionsCollection.length <= 0 && RS.promotionsView.searchdata.page <=1 && RS.promotionsView.searchdata != ''){
               this.$el.children('div').not('.showempty').css('display','none').siblings('.showempty').css('display','block')
               .find('span.keyword').text(RS.promotionsView.searchdata.search);
               RS.featPromoView.hide();         
             }
             else{
               this.$el.children('div').not('.showempty').css('display','block').siblings('.showempty').css('display','none');
               if(RS.promotionsView.searchdata.search){
                 RS.featPromoView.hide();
               }
               else{
                 RS.featPromoView.show();
               }
                   
             }
            if(RS.promotionsView.searchdata.page< 2 && this.$el.is(':visible')){
              $('html,body').animate({scrollTop:'0px'});
            }
          },this);
          RS.promotionsCollection.on('error',function(model,resp,options){
            if(resp.status == 400){
              this.$el.children('div').not('.showempty').css('display','none').siblings('.showempty').css('display','block')
               .find('span.keyword').text(RS.promotionsView.searchdata.search);
               RS.featPromoView.hide();     
            }
          },this);

    },

    events: {
        'click #promotions-view .btn-more': 'showMore',
        //'click #featpromo-view':'login',
        'click .btn-redeem':'redeemClick',
        'click .btn-login':'login',
        'click .searchinfo-view .btn-clear':'clearSearch',
        'click .showempty .btn-clear':'emptyClearSearch'
    },        
    
    login:function(e){
        e.preventDefault();
    	var $btn = $(e.target);
    	var promoID = $btn.closest("[data-promotionid]").attr("data-promotionid");
    	if (promoID) {
    		RS.login_redirect = "#!promotions/"+promoID+"/redeem";
    		window.router.navigate('#!login',{trigger:true});
    	}
    },
    emptyClearSearch:function(e){
      this.$el.find('.showempty').hide();
      this.showLoading('#promotions-view');
      this.$el.find('#promotions-view').show();
      this.clearSearch(e);
    },
    clearSearch:function(e){
      $('#search-keyword-input').val('').siblings().find('#search-keyword-btn').trigger('click');
    },
    redeemClick: function (e) {
    	e.preventDefault();
    	var $btn = $(e.target);
    	var promoID = $btn.closest("[data-promotionid]").attr("data-promotionid");
    	if (promoID) {
            window.router.navigate("#!promotions/"+promoID+"/redeem",{trigger:true});
    	}
    },
    showMore: function (e) {
        e.preventDefault();
        this.showLoading('#promotions-view');
        RS.promotionsView.searchdata.page +=1;
        RS.promotionsView.fetch();
    },
    showLoading: function (target) {
          this.$el.find(target).find('.spinner-container').show();
    },
    show: function(type, str){
      // null, search, category
    	//console.log("showing type: "+type);
    	//console.log("showing str: "+str);
      //RS.featPromoView.show();
      if(!this.refresh){
        //this.searchdata.page = 1;
        if(!type && RS.promotionsView.searchdata.search){
            $('#search-keyword-input').val('');
            $('#search-keyword-btn').trigger('click');
        }
        else if(type){
            window.location.reload();
        }else{
            var pages = RS.promotionsView.searchdata.page;
            RS.promotionsView.searchdata.pageSize = pages * 12;
            RS.promotionsView.searchdata.page = 1;
            RS.promotionsCollection.fetch({data:RS.promotionsView.searchdata}).done(function(){
                RS.promotionsView.searchdata.page = pages;
                RS.promotionsView.searchdata.pageSize = 12;
            });
            RS.featPromoView.slider.reloadSlider();
        }
        
      }
      
      else{
        this.refresh = false;
      }
      $(window).trigger('scroll');
      this.$el.show();
      if(RS.featPromoView.slider){
        RS.featPromoView.slider.reloadSlider();
      }
      
    },
    hide: function(){
      this.$el.hide();
    },
  });

  RS.Views.Promotions = RS.Views.BaseView.extend({
      defaults: {
          searchdata: {
              search:'',
              categoryID: 0,
              propertyID:0,
              pageSize: 12,
              page: 1,
              sort:'published',
              sortDir: 'DESC',
              publicPromo:'yes',
              currency: 'RWG GP',
          },
      },
      initialize: function (options) {
          this.showLoading();
          this.collection.on('sync', this.render, this);
          this.itemTemplate = _.template($('#template-promotion').html(), { variable: 'data' }); // promotion template
          this.template = _.template($('#template-promotions').html(), { variable: 'data' });
          this.searchdata = _.extend({},this.defaults.searchdata,options.searchdata?options.searchdata: {});
      },

      events: {
        //'click .promotion-container .btn-details':'routerDetail',
        'click .item-promotion .btn-like':'likePromotion',
        'click .item-promotion .btn-dislike':'dislikePromotion',
      },
      fetch:function(){
          if(this.searchdata.page <=1){
            this.$el.find('#promotions-container').html('');
            this.showLoading();
          }
          
          this.collection.fetch({data:this.searchdata});
      },
      render: function () {
          var collections = this.collection.toJSON();
          if(collections.length >0){
            if(this.searchdata.page <= 1){
                this.$el.find('#promotions-container').html(this.template({
                    promotions: collections,
                    itemTemplate:this.itemTemplate,
                    currency:RS.currency ||'RWG GP',
                }));
            }
            else{
                this.$el.find('#promotions-container').append(this.template({
                    promotions: collections,
                    itemTemplate:this.itemTemplate,
                    currency:this.searchdata.currency,
                }));
            }
            this.hideLoading();
            if (collections.length < 12) {
                this.$el.find('.btn-more').hide();
            }
            else {
                this.$el.find('.btn-more').show();

            }
          }
          else{
             this.hideLoading();
             this.$el.find('.btn-more').hide();
          }
          if ($("#masterLoading").is(':visible')){
              window.app.hideMasterLoading(); 
          }
      },
      routerDetail:function(e){
        e.preventDefault();
        e.stopPropagation();
        window.router.navigate($(e.currentTarget).attr('href'),{trigger:true});
      },
      show: function () {

          this.$el.show();
      },
      hide: function () {
          this.$el.hide();
      },
  });

  RS.Views.FeatPromo = RS.Views.BaseView.extend({

      initialize: function (options) {
          this.showLoading();
          this.collection.on('sync', this.render, this);
          this.itemTemplate = _.template($('#template-featpromo').html(), { variable: 'data' }); // promotion template
          this.staticTemplate = _.template($('#template-staticpromo').html(), { variable: 'data' }); //static promotion template
          this.template = _.template($('#template-featpromos').html(), { variable: 'data' });
      },

      events: {
      },

      render: function () {
        this.$el.find('#featpromo-container').html(this.template({
            currency:RS.currency ||'RWG GP',
            promotions: this.collection.toJSON(),
            itemTemplate: this.itemTemplate,
            staticTemplate: this.staticTemplate,
        }));
        this.hideLoading();
        this.slider = this.$el.find('.bxslider').bxSlider({
            auto: (this.$el.find('.bxslider').children().length < 2) ? false : true,
            autoControls: true,
            pause: 6000, 
            onSliderLoad:function(currentIndex){
              $('.bxslider').find('.fade').removeClass('fade');
              var $target = $('.bxslider').find('li[data-slide-index='+currentIndex+']');
              if($target.attr('data-static')=='false'){
                  $target.find('.info').addClass('fade'); 
              }
            },
            onSlideAfter:function($slideElement, oldIndex, newIndex){
              $('.bxslider').find('.fade').removeClass('fade');
              if($slideElement.attr('data-static')=='false'){
                $slideElement.find('.info').addClass('fade');
              }
              window.Redso.featPromoView.slider.stopAuto();
              window.Redso.featPromoView.slider.startAuto();
            },
        });
      },

      show: function () {
          if(this.collection.length<=0){
            this.collection.fetch();
          }else{
            this.render();
          }
          this.$el.show();
      },
      hide: function () {
          this.$el.hide();
      },
  });

})(window.Redso);
