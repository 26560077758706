(function (RS) {
    'use strict';

    RS.Collections.Promotions = RS.Collections.GRA.extend({
        model: RS.Models.Promotion,

        sync: function(method, model, options){
          if(options.data){
              options.data.CountryCode = RS.Geo.get('CountryCode');
          }
          return RS.Collections.GRA.prototype.sync(method, model, options);
        },
        //url: '/apis/get_brands',
        url: RS.apiUrl + '/IPromos/GetPublicPromos',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });

    RS.Collections.FeatPromo = RS.Collections.Promotions.extend({
        //url: '/apis/get_brands',
        sync: function(method, model, options){
            if(!options.data){
                options.data = {};
            }
            options.data.CountryCode = RS.Geo.get('CountryCode');
            return RS.Collections.GRA.prototype.sync(method, model, options);
        },
        url: RS.apiUrl + '/IPromos/GetFeaturedPromos',
    });

    RS.Collections.FavPromo = RS.Collections.Promotions.extend({
        sync: function(method, model, options){
          if (RS.cardID) {
            options.url = RS.apiUrl+'/IPromos/GetFavourites?cardID='+RS.cardID; 
          } else {
            options.url = RS.apiUrl+'/IPromos/GetPublicFavourites'; 
          }
          return RS.Models.GRA.prototype.sync(method, model, options);
        },
    });

    RS.Collections.LinkPromo = RS.Collections.Promotions.extend({
        url: RS.apiUrl + '/IPromos/GetRelatedPromos',
        sync: function(method, model, options){
          if(options.data){
              options.data.CountryCode = RS.Geo.get('CountryCode');
          }
          return RS.Collections.GRA.prototype.sync(method, model, options);
        },
    });
})(window.Redso);