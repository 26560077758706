(function(RS){
  "use strict";

  RS.Views.GetAppView = RS.Views.BaseView.extend({
	el: '#getapp-container',

    initialize: function(options){
    },

    render: function(){
    },
    events: {
    },

    show: function(){
      this.$el.css({"background-image":"url("+window.location.pathname+"images/bg.jpg)"});
      this.$el.find('.content-container').append('<img src="'+window.location.pathname+'images/content.png"/>');
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },

  });
})(window.Redso);
