(function(RS){
  "use strict";

  RS.Views.AboutView = RS.Views.BaseView.extend({
	el: '#ourbrands-container',

    initialize: function(options){
    },

    render: function(){
    },
    events: {
    },

    show: function(){
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },

  });
})(window.Redso);
