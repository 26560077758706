(function(RS){
  "use strict";

  RS.Views.ContestsView = RS.Views.BaseView.extend({

    initialize: function(options){

    },

    events: {
        'click .gen-nav .item': 'navItemClick',
        'click .sections>a.headerbtn': 'mobileItemClick',
        'click .gra-checkbox':'GRACheckboxClick',
        'submit form.contest-form':'ContestFormSubmit',
        'click #contest-success-modal button': 'backToHome',
				'click a.to-enter-now':'toEnterNow',
				'click a.contesttmc':'toTmc',
    },
		toEnterNow:function(e){
			e.preventDefault();
			this.$targetContest.find('.gen-nav .item[data-section="capenternow"]').trigger('click');
			$('body').scrollTop(0);
		},
		toTmc:function(e){
			e.preventDefault();
			this.$targetContest.find('.gen-nav .item[data-section="captnc"]').trigger('click');
			$('body').scrollTop(0);
		},
    backToHome:function(e){
      $('#contest-success-modal').modal('hide');
      window.router.navigate('#!home', {trigger: true});
    },
    ContestFormSubmit:function(e){
      e.preventDefault();
      var $form = $(e.currentTarget);
      var $parsForm = $form.parsley();
      if(!this.isDate){
        return;
      }
      if (!$parsForm.validate()) {
        return;
      }

      var url = window.location.pathname +"contests.php";
      $.ajax({
        url:url,
        type:'get',
        data:$form.serialize()
      }).done(function(resp){
    	  var msg = '';
        var respObj = $.parseJSON(resp.trim());
    	  if (respObj && respObj.ModelState) {
    		  $.each(respObj.ModelState, function(k, v) {
    			  _.each(v,function(i){
    			    msg += "<p>" + i + "</p>";
    			  });            
    		  });
					if( respObj.failed && respObj.failed == 'signup' ){
						msg += '<p class="text-center"><a class="gra-btn font-white btn-red" href="#!register" style="color:white;margin-top:15px;">Sign Up Now!</a></p>';
					}
          $form.siblings('.error-list').html(msg).show();
    	  }
        else if(respObj && respObj.Value){
          var $modal = $('#contests-container #contest-success-modal');
          $modal.find('.modal-body').html('<p>'+respObj.Value+'</p>');
          $modal.modal('show'); 
          //$modal.find('.modal-header .modal-title').html();
					
        }
        else if(respObj && respObj.failed && respObj.failed == 'captcha'){
          var $modalerr = $('#contests-container #contest-error-modal');
          $modalerr.find('.modal-body').html(wd.js_captcha_expired);
          $modalerr.modal('show'); 
        }    
      }).fail(function(failed){
        console.log('failed call set contest');
      });
    },
    navItemClick: function(e){
    	e.preventDefault();
    	var $thisitem = $(e.target), section = $thisitem.attr("data-section"), $navDesk = $thisitem.closest('.gen-nav'), $sections = $navDesk.siblings('.sections');
    	if (!$thisitem.hasClass("active")) {
            this.itemClickHandler($thisitem, section, $navDesk, $sections);
            this.viewHandler(section);
    	}
    },

    mobileItemClick:function(e){
      e.preventDefault();
    	var $thisitem = $(e.target), section = $thisitem.attr("data-section"), $navDesk =$thisitem.closest('.contest').find('.gen-nav'), $sections = $thisitem.closest('.sections');
    	if (!$thisitem.hasClass("collapsed")) {
          this.itemClickHandler($thisitem, section, $navDesk, $sections);
          this.viewHandler(section);
    	}
      else{
          $thisitem.removeClass("collapsed").attr('aria-expanded','false');
          $sections.find(".section."+section).removeClass('in').css('height',0);
      }
    },

    itemClickHandler:function($thisitem,section,$navDesk,$sections){
       $navDesk.find(".item.active").removeClass("active");
       $navDesk.find('[data-section="'+ section +'"]').addClass('active');
       $sections.find('a.collapsed').removeClass("collapsed").attr('aria-expanded','false');
       $sections.find('[data-section="'+ section +'"]').addClass("collapsed").attr('aria-expanded','true');
    },
    
    viewHandler:function(section){
    		this.$el.find(".section").removeClass('in').css('height',0);
        var $targetSection = this.$el.find(".section."+section);
    		$targetSection.addClass('in').css('height','auto');
        if(ResponsiveBootstrapToolkit.is('xs')){
          $(window).scrollTop(0);
        }
    },

    show: function(str){
      this.$targetContest = this.$el.find('#'+str);
      var $form = this.$targetContest.find('form');
      var contestid = this.$targetContest.find('input[name="CampaignID"]').val();
      var url = RS.apiUrl + '/IMisc/GetCampaign';
      var _this = this;
      $.ajax({
        url:url,
        type:'get',
        data:{"id":contestid}
      }).done(function(resp){
        var msg = '';
        var enddate = moment(resp.EndDate);
        var now = moment();
        _this.isDate = now.isBefore(enddate);
        if(!_this.isDate){
          var $modal = $('#contests-container #contest-error-modal');
          $modal.addClass('no-close').find('.modal-header button').remove();
				  $modal.find('.modal-header').html('<a type="button" class="close" href="#home"><div class="cross"></div></a>');
          $modal.find('.modal-body').html(wd.js_compaigned_out_of_date);
          //$modal.find('.modal-header .modal-title').html();
          _this.$targetContest.show().siblings().hide();
          _this.$el.show();
          $modal.modal('show'); 
        }
        else{
					_this.$targetContest.find('.promo-link').attr('href','#!promotions/'+resp.promotionID);
					_this.$targetContest.find('.promo-link-text').html('#!promotions/'+resp.promotionID);
				  _this.$targetContest.find('[data-toggle="tooltip"]').tooltip();
          _this.$targetContest.show().siblings().hide();
          _this.$el.show();
        }
      }).fail(function(resp){
        console.log('get campaign enddate has error');
      });
      
      //this.$targetContest.find(".sections .section").removeClass('in').css('height',0);
      //this.$targetContest.find(".gen-nav .item.active").removeClass("active");
      //this.$targetContest.find('.sections .headerbtn[data-section]').removeClass('collapsed').attr('aria-expanded','false');
      
    },
    hide: function(){
      this.$el.hide().find('.contest').hide();
      var _this = this;
			if(this.$targetContest){
        this.formReset(this.$targetContest.find('form'));
				grecaptcha.reset(CaptchaList[this.$targetContest.prop('id')]);
				this.$targetContest = '';
			}
      this.$el.find('#contest-error-modal').modal('hide');
      this.$el.find('#contest-success-modal').modal('hide');
    },


  });
})(window.Redso);