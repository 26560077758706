(function (RS) {
    'use strict';
    RS.Models.ContestAd = RS.Models.GRA.extend({
    });

    RS.Collections.ContestAds = RS.Collections.GRA.extend({
        model: RS.Models.GRA,
        url: RS.apiUrl + '/IMisc/GetMarketingLink',

    });

})(window.Redso);