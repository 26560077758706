(function (RS) {
    'use strict';

    RS.Collections.GRA = Backbone.Collection.extend({
        model: RS.Models.GRA,

        initialize: function(options){
          this.on('error', function(options, resp){
              if (resp && resp.status == 401 && RS.access_token) {
                  RS.GRALogout("#login");
              }
          }, this);
        },

        sync: function(method, collection, options) {
          options.headers = {};
          if (RS.access_token) {
              options.headers.Authorization = "bearer "+RS.access_token;
          }
          options.headers['Accept-Language'] = RS.lang;
          return Backbone.sync(method, collection, options);
        },
    });

})(window.Redso);