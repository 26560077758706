(function(RS){
  "use strict";

  RS.apiUrl = window.location.protocol +(window.location.hostname=='www.gentingrewards.com'?'//members.gentingrewards.com/gra/api/v2':'//memberstest.gentingrewards.com/grnapp/api/v2');
  //'//memberstest.gentingrewards.com/grnapp/api/v2'
  //'//members.gentingrewards.com/gra/api/v2/'
  
  RS.Models.GRA = Backbone.Model.extend({
    initialize: function(options){
      // Called during the model is instantizing (E.g. new RS.Models.User({name: 'dave', ...});)
      this.on('error', function(options, resp){
    	  if (resp && resp.status == 401 && RS.access_token) {
    		  RS.GRALogout("#login");
    	  }
    	  /*
        if (resp.responseText)
          RS.alert({message: resp.responseText});
        else
          RS.alert({message: resp.status + ' ' + resp.statusText});
          */
      }, this);
    },

    // Called when the data is fetched from the server. Could be called by model.set()
    // Might create attributes that are not on the server.
    // For example, convert unix timestamp (string) to moment object.
    parse: function(response, options) {
      // response.ctime = moment(response.ctime);
      return response;
    },

    sync: function(method, model, options) {
      options.headers = {};
      if (RS.access_token) {
    	  options.headers.Authorization = "bearer "+RS.access_token;
      }
      options.headers['Accept-Language'] = RS.lang;
      return Backbone.sync(method, model, options);
    },
  });
  
  // A dummy page data
  RS.Models.PageData = RS.Models.GRA.extend({
  });

  RS.Models.Token = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/token',
  });

  RS.Models.FBLogin = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/IMemberAccount/FacebookLogin',
  });

  RS.Models.ResendVerification = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/IMisc/ResendVerification',
  });

  RS.Models.ResendFgtPwd = RS.Models.GRA.extend({
    urlRoot: RS.apiUrl+'/IMisc/ResendForgotPassword',
  });


  RS.Models.Category = RS.Models.GRA.extend({
      url: RS.apiUrl + '/IPromos/GetPublicPromotionsCategory',
      sync: function(method, model, options){
          if(options.data){
              options.data.CountryCode = RS.Geo.get('CountryCode');
          }
          return RS.Models.GRA.prototype.sync(method, model, options);
      },
      parse:function(resp, options){
        return resp;
      },
  });

  RS.Models.Promotion = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IPromos/GetPublicPromos',
      idAttribute:'ID',
      sync: function(method, model, options){
          if(options.data && !RS.first_in){
              options.data.CountryCode = RS.Geo.get('CountryCode');
          }
          return RS.Models.GRA.prototype.sync(method, model, options);
      },
      parse:function(resp){
        if(_.isArray(resp)){
          resp = resp[0];
        }
        if(resp){
          if (resp.ValidityStartDate) {
    		    resp.ValidityStartDate = moment(resp.ValidityStartDate);
    	    }
          else{
            resp.ValidityStartDate = moment();
            //console.log(resp);
          }
          if (resp.ValidityEndDate) {
    		    resp.ValidityEndDate = moment(resp.ValidityEndDate);
    	    }
          else{
            resp.ValidityEndDate = moment();
            //console.log(resp);
          }
          if (resp.PublishedEndDate) {
    		    resp.PublishedEndDate = moment(resp.PublishedEndDate);
    	    }
          else{
            resp.PublishedEndDate = moment();
            //console.log(resp);
          }
        }
        return resp;
      }
  });

  RS.Models.Property = RS.Models.GRA.extend({
      urlRoot: RS.apiUrl + '/IData/GetProperties',
  });

  RS.Models.PromoLine = RS.Models.GRA.extend({
      idAttribute:'ID',
      urlRoot: RS.apiUrl + '/IPromos/GetPromotionLines',
  });
})(window.Redso);
