(function(RS){
  "use strict";

  RS.Models.Card = RS.Models.GRA.extend({
    sync: function(method, model, options){
      if (method=='save'){
        options.url = RS.apiUrl+'/ILinkedCards/LinkCard'; 
      } else if (method=='create'){
        
        options.url = RS.apiUrl+'/ILinkedCards/' + (model.get('DRSPin')?'LinkCardWithPin':'LinkCard'); 
      } else {
        options.url = '';
      }
      return RS.Models.GRA.prototype.sync(method, model, options);
    },
    
    idAttribute: 'ID',

    parse: function(resp, options){
      if (resp.CardAddedDate) {
        resp.CardAddedDate = moment(resp.CardAddedDate);
      }
      if (resp.LastUpdatedDate) {
        resp.LastUpdatedDate = moment(resp.LastUpdatedDate);
      }
      if (resp.PointsExpiryDate) {
        resp.PointsExpiryDate = moment(resp.PointsExpiryDate);
      }
      return resp;
    }
  });
  RS.Models.VerifyCardPin = RS.Models.GRA.extend({
      // idAttribute:'ID',
      urlRoot: RS.apiUrl + '/ILinkedCards/VerifyCardWithPin',
      url:RS.apiUrl + '/ILinkedCards/VerifyCardWithPin',
      sync: function(method, model, options){
         options.url =RS.apiUrl + '/ILinkedCards/VerifyCardWithPin?' + options.data;
         return RS.Models.GRA.prototype.sync(method, model, options);
      }
  });

  RS.Models.VerifyCard = RS.Models.GRA.extend({
      idAttribute: 'idid1234',
      sync: function(method, model, options){
          switch (method){
             case 'read':
               options.url = RS.apiUrl+'/ILinkedCards/LinkCardOTP'; 
               break;
             case 'create':
               options.url = RS.apiUrl+'/ILinkedCards/OTP?'+options.data; 
               break;
             default:
               options.url = '';
          }

          return RS.Models.GRA.prototype.sync(method, model, options);
      },
  });

  RS.Models.CardPoint = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/ILinkedCards/UpdatePoints?'+$.param(options.data);
      options.type = 'PUT';

      return RS.Models.GRA.prototype.sync(method, model, options);
    },
    
    idAttribute: 'ID',

    parse: function(resp, options){
      if (resp.CardAddedDate) {
        resp.CardAddedDate = moment(resp.CardAddedDate);
      }
      if (resp.LastUpdatedDate) {
        resp.LastUpdatedDate = moment(resp.LastUpdatedDate);
      }
      if (resp.PointsExpiryDate) {
        resp.PointsExpiryDate = moment(resp.PointsExpiryDate);
      }
      return resp;
    }
  });

})(window.Redso);
