(function(RS){
  "use strict";

  RS.Views.ForgetPWDView = RS.Views.BaseView.extend({
    initialize: function(options){
      this.form = this.$el.find("#forgetpwd-form").parsley();
      this.$form = this.$el.find("#forgetpwd-form");
    },

    events: {
    	/*
      'click .btn-login': 'loginClicked',
      'click .btn-fb-login': 'fbLoginClicked',
      */
      'submit form': 'formSubmitted',
      'click #resend_fgt_verify': 'resendFgtVerification'
    },
    show: function(){
      this.$el.find('.error-list').empty().hide();
      this.formReset(this.$form);
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },
    resendFgtVerification:function(){
      this.resendfgtModel = new RS.Models.ResendFgtPwd();
      var input ={
          Email: $("#forgetpwd-form input[name=Email]").val(),
          };
      this.resendfgtModel.on('sync',function(model, respObj, options){
          var $modal = $('#forgetfailed-modal');
           var msg = '';
          if (respObj && respObj.ModelState.ErrorMessage) {
              msg = respObj.ModelState.ErrorMessage.join('</p><p>');
    	    }
          $modal.find('.modal-body').append('<p class="font-red">'+ msg +'</p>').find('#resend_fgt_verify').hide();
      });
      this.resendfgtModel.fetch({data:input, type: 'POST'});
    },
    formSubmitted: function(e) {
    	e.preventDefault();
    	var $form = $(e.target);
      var that = this;
    	if (this.form.validate()) {
            window.app.showMasterLoading();
    		var pwdObj = new RS.Models.UserForgetPassword();
    		var params = $form.serialize();
            pwdObj.save(null, {data:params, 'type':'PUT'}).done(function(data) {
            	if (data.Message) {
                    RS.alert({"message": data.Message});
            	}
              that.formReset($form);
    		}).fail(function(failed) {
                var resp = failed.responseJSON;
                var msg = '';
                if (resp && resp.ModelState.ErrorMessage) {
    	                _.each(resp.ModelState.ErrorMessage,function(i){
    		                msg += "<p>" + i + "</p>";
    	                });            
                    }
                else {
                    msg=failed.responseJSON.Message || wd.js_default_error_code;
                }
                if(failed.status == 406){
                msg += '<button class="gra-btn" id="resend_fgt_verify">'+ wd.js_resend_verification_email +'</button>';
                } 
                var $modal = $('#forgetfailed-modal');
                $modal.find('.modal-body').html(msg);
                $modal.modal('show');   
    		}).always(function() {
                window.app.hideMasterLoading();
    		});
    	}
    },

  });
})(window.Redso);
