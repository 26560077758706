(function (RS) {
    'use strict';

    RS.Collections.Redemptions = RS.Collections.GRA.extend({
        model: RS.Models.Redemption,

        url: RS.apiUrl + '/IRedemptions/GetAll?type',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });

    RS.Collections.ActiveVouchers = RS.Collections.GRA.extend({
        model: RS.Models.Redemption,

        url: RS.apiUrl + '/IRedemptions/GetAll?type=Active',

        parse: function (resp, options) {
            if (resp) {
            	resp.group = 'Active';
                return resp;
            }
        },

    });

    RS.Collections.ConsumedVouchers = RS.Collections.GRA.extend({
        model: RS.Models.Redemption,

        url: RS.apiUrl + '/IRedemptions/GetAll?type=Consumed',

        parse: function (resp, options) {
            if (resp) {
            	resp.group = 'Consumed';
                return resp;
            }
        },

    });

    RS.Collections.ExpiredVouchers = RS.Collections.GRA.extend({
        model: RS.Models.Redemption,

        url: RS.apiUrl + '/IRedemptions/GetAll?type=Expired',

        parse: function (resp, options) {
            if (resp) {
            	resp.group = 'Expired';
                return resp;
            }
        },

    });
})(window.Redso);