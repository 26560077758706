(function (RS) {
    'use strict';

    RS.Collections.Cards = RS.Collections.GRA.extend({
        model: RS.Models.Card,

        url: RS.apiUrl + '/ILinkedCards/GetLinkedCards?type=All',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });

    RS.Collections.EligibleCards = RS.Collections.GRA.extend({
        model: RS.Models.Card,

        url: RS.apiUrl + '/IPromos/GetEligibleCards',

        parse: function (resp, options) {
            if (resp)
                return resp;
        },

    });
})(window.Redso);