(function(RS){
  "use strict";

  RS.Views.BaseView = Backbone.View.extend({
    initialize: function (options) {

    },

    events: {
      'click button.btn-ok':'closeModal'
    },

    show: function(){
    	console.log("base view show");
      //this.$el.show();
    },
    hide: function(){
    	console.log("base view hide");
      //this.$el.hide();
    },

    closeModal:function(e){
      $(e.currentTarget).closest('.modal').modal('hide');
    },
    showLoading: function(){
      if (this.$el.find('.spinner-container').length > 0){
        this.$el.find('.spinner-container').show().siblings().hide();
      } else {
        window.app.showLoading();
      }
    },

    hideLoading: function(){
      if (this.$el.find('.spinner-container').length > 0){
        this.$el.find('.spinner-container').hide().siblings().show();
      } else {
        window.app.hideLoading();
      }
    },
   
    setGRADropDown:function(e){
      e.preventDefault();
      var $target = $(e.target);
      if(RS.changeFake && $target.is('select')){
        RS.changeFake = false;
      }
      else{
        var $parent = $target.closest(".gra-dropdown-select");
        if (e.target.tagName.toLowerCase() == 'a') {
      	  var val = $target.attr("data-val");
      	  var label = $target.text();
      	  $target.closest("ul").find("li").removeClass("active");
      	  $target.closest("li").addClass("active");
          if(!$target.is('select') && !RS.changeFake){
            RS.changeFake = true;
            $parent.find("select").val(val).change();
          }
          else{
            $parent.find("select").val(val);
            RS.changeFake = false;
          }
      	  $parent.find("button").text(label);
        } else {
      	  var targetval = $target.val();
      	  $parent.find("ul>li>a[data-val='"+targetval+"']").click();
        }
      }
    },
   
    setGRASearchSelect:function(e){
        e.preventDefault();
        var $target = $(e.target);
        var $parent = $target.closest(".gra-search-select");
        if ($target.val()) {
            var targetval = $target.find("option:selected").text().trim();
            $parent.find("input").val(targetval).trigger('change');
        } else {
            $parent.find("input").val('').trigger('change');
        }
    },

    GRARadioClick:function(e){
    	e.preventDefault();
        var $target = $(e.target);
        if ($target.hasClass("checked")) { return false; }
        if ($target.attr("data-name")) {
        	var name = $target.attr("data-name");
        	$("input[type=hidden][name="+name+"]").val($target.attr("data-value"));
        	$(".gra-radio[data-name="+name+"]").removeClass("checked");
        }
        $target.addClass("checked");
    },
    GRACheckboxClick:function(e){
    	e.preventDefault();
        var $target = $(e.target);
        if ($target.attr("data-name")) {
          var name = $target.attr("data-name");
          var $checkbox = $("input[type=checkbox][name="+name+"]");
          if($target.hasClass("checked")){
        	$checkbox.prop('checked',false).change();
            $target.removeClass("checked");
          }
          else{
            $checkbox.prop('checked',true).change();
            $target.addClass("checked");
          }
        }
    },

    GRALogout:function(e){
    	e.preventDefault();
        var $form = $("<form>");
        $form.attr("action", window.location.pathname).attr("method", "post");
        $form.append("<input type='hidden' name='logout' value='1'/>");
        $('body').append($form);
        $form.submit();
    },

    formReset:function($form){
      $form[0].reset();
      $form.find(':password').val('');
      //$form.find('.parsley-success').removeClass('parsley-success');
      //$form.find('.parsley-error').removeClass('parsley-error').siblings('ul.parsley-errors-list').remove();
      var slider = $form.parsley();
      slider.reset();
      var listItem = $form.find('.dropdown-menu li').filter(':first-child').find('a');
      _.each(listItem,function(item){
        RS.changeFake = true;
        $(item).trigger('click');
      });
      $form.find('.gra-checkbox').removeClass('checked').siblings('input[type="checkbox"]').prop('checked',false);
      $form.siblings('.error-list').html('').hide();
    },
    toggle: function(e){
        var target = $(e.currentTarget).attr('data-target');
        var toaction = $(e.currentTarget).attr('data-type');
        var exaction = $(target).attr('data-action');
        if (exaction == toaction) {
            $(target).attr('data-action', '');
            setTimeout(function(){ $('html,body').removeClass('overflow'); }, 250);
        }
        else {
            $('html,body').addClass('overflow');
            $(target).attr('data-action', toaction);
        }
    },
    toggleMenu:function(e){
        e.preventDefault();
        this.toggle(e);
    },
    likePromotion:function(e){
      if(RS.curUser && !$.isEmptyObject(RS.curUser.toJSON())){
         var likePromo =  new RS.Models.LikePromotion(),
             id = this.model?this.model.get('ID'):$(e.target).attr('data-id');

         likePromo.save(null, 
            {data:{promoID:id},
             success:function(){
               $(e.currentTarget).hide().siblings('.btn-dislike').show(); 
               RS.favourites.fetch();
              //RS.promotionsView.fetch();
               var $item = $(e.currentTarget).closest('.item-promotion');
               if(window.app.viewName == 'promotion' && $item.length <= 0 ){
                 $('#promotion-detail').find('.like-btn-group .btn-like').hide().siblings('.btn-dislike').show(); 
               }  
               if($item){
                 $item.find('.promotion-img span.btn-dislike').show();
               }
             },
             error:function(){
               console.log('fail');
             },
             wait:true,
             parse: false,
             dataType: 'text',});

      }
      else{

      }

    },
    dislikePromotion:function(e,callback){
      if(RS.curUser && !$.isEmptyObject(RS.curUser.toJSON())){      
         var DislikePromo =  new RS.Models.DislikePromotion(),
             id = this.model?this.model.get('ID'):$(e.target).attr('data-id');
         DislikePromo.save(null, 
         { data:{promoID:id},
           success:function(){
              $(e.currentTarget).hide().siblings('.btn-like').show(); 
              RS.favourites.fetch();
             // RS.promotionsView.fetch();
              var $item = $(e.currentTarget).closest('.item-promotion');
              if(window.app.viewName == 'promotion' && $item.length <= 0){
                 $('#promotion-detail').find('.like-btn-group .btn-dislike').hide().siblings('.btn-like').show(); 
              }
              if($item){
                $item.find('.promotion-img span.btn-dislike').hide();
              }
              if(callback)
                callback(e);
           },
           error:function(){
              console.log('fail');
           },
           wait:true,
           parse: false,
           dataType: 'text',
         });
      }
      else{

      }

    },
  });
})(window.Redso);
