(function (RS) {
    "use strict";
    RS.Views.LeftMenuView = RS.Views.BaseView.extend({
        defaults: {
            searchdata: {
                categoryID: 0,
                pageSize: 12,
                page: 1,
                propertyID: 0,
                currency: RS.currency_default,
                search: '',
                //sort:'published',
                //sortDir: 'DESC',
                publicPromo:'yes',
                cardID:0,
            },
            catdata:{
                propertyID: 0,
                currency: RS.currency_default,
                search:'',
                cardID:0,
                publicPromo:'yes',
            },
            order:[
              {sort:'published',
               sortDir:'DESC'},
              {sort:'price',
               sortDir:'DESC'},
              {sort:'price',
               sortDir:'ASC'},
              {sort:'views',
               sortDir:'DESC'},
              {sort:'redeems',
               sortDir:'DESC'},
           ],
        },
        initialize: function (options) {
            this.options = _.extend(this.defaults, this.options);
            this.searchdata = _.extend({}, this.defaults.searchdata);
            this.catdata = _.extend({}, this.defaults.catdata);

            this.categoryModel = new RS.Models.Category();
            this.categoryView = new RS.Views.CategoryView({
                el: '#left-menu .category-container',
                model: this.categoryModel,
            });

            RS.propertyCollection = new RS.Collections.Properties();
            RS.propertyView = new RS.Views.PropertyView({
                el: '#left-menu .property-container',
                collection: RS.propertyCollection,
            });

            RS.cardView = new RS.Views.CardsView({
                el: '#left-menu .card-container',
            });

            this.leftLoginView = new RS.Views.NavbarLoginView({
                el:'#left-menu .login-btn-group',
            });
            this.refresh = true;
            var hash = window.location.hash;
            if(hash.indexOf('#!search')>=0){
                var str = hash.slice(hash.indexOf('/')+1);
                this.searchInfo = decodeURIComponent(str);
                this.isSearch = true;
            }
            if(hash.indexOf('#!category')>=0){
              this.isCat = true;
            }

			RS.propertyCollection.on('sync', function () {
				if(RS.property_default){
					_.each(RS.propertyCollection.toJSON(),function(property){
						if(property.PropertyCode == RS.property_default){
							this.searchdata.propertyID = property.ID;
							this.catdata.propertyID = property.ID;
						}
					},this);
				}
				_.extend(this.searchdata, this.defaults.order[RS.sortby_default],{page:1});
				var ordername = this.$el.find('#select-menu-sort .dropdown-menu').find('li[data-id="'+ RS.sortby_default+'"]').addClass('active').find('a').text();
				this.$el.find('#select-sort-btn span.name').html(ordername);
                RS.propertyView.render();
            }, this);
        },

        events:{
            'click .category-container .cat-list li': 'getCategory',
            'click #select-menu-property li': function (e) { this.getSelect(e, 'propertyID');},
            'change select[name=select-property]':'triggerSelect',
            'click #select-menu-currency li': function (e) { this.getSelect(e, 'currency');},
            'change select[name=select-currency]': 'triggerSelect',
            'click .card-container .card-list li': 'getCard',
            'click button#search-keyword-btn':'getKeyword',
            'click #select-menu-sort li': 'getSort',
            'change select[name=select-sort]': 'triggerSelect',
            'keypress input#search-keyword-input':'triggerSearch',
            'keyup input#search-keyword-input':'getSearch',
            'keydown input#search-keyword-input':'getSearch',
        },
        getSearch:function(e){
          this.searchdata.search = $(e.target).val();
          this.catdata.search = this.searchdata.search;
        },
        triggerSearch:function(e){
           this.getSearch(e);
           return e.which == 13 ? $('#search-keyword-btn').trigger('click'):e;   
        },
        triggerSelect: function (e) {
            var $target = $(e.currentTarget);
            var value = $target.val();
            $target.siblings('.dropdown').find('li[data-id="' + value + '"]').trigger('click');
        },

        getKeyword:function(e){
            e.preventDefault();
            $('input').blur();
            var keyword = $('#search-keyword-input').val();
            this.catdata.search = keyword;
            this.categoryView.fetch(this.catdata);
            this.searchdata.search = keyword;
            this.searchdata.categoryID = 0;
            if(!this.refresh ){
              if(keyword){
                 window.router.navigate('#!search/'+ encodeURIComponent(keyword), { trigger: false });
              }
              else{
                 if(window.location.hash.indexOf('#!search')>=0){
                   window.router.navigate('#!',{trigger:false});
                 }
              }
            }
            else{
              this.refresh = false;
            }
            this.searchHome();
        },
        getCard:function(e){
          var $target = $(e.currentTarget);
          var name = $target.html();
          var currency = $target.attr('data-currency');
          RS.currency = currency;
          RS.featPromoView.render();
          
          //save cardID in session
          window.app.setSelectedCard($target.attr('data-id'));
          RS.favourites.fetch();
          $target.addClass('active').siblings('.active').not(this).removeClass('active');
          //$target.closest('.dropdown').siblings('button[data-toggle="dropdown"]').html(name);
          this.catdata.currency = currency;
          this.catdata.propertyID = 0;
          this.catdata.cardID = RS.cardID;
          this.searchdata = _.extend(this.searchdata,{
            currency:currency,
            propertyID:0,
            categoryID:0,
            cardID:RS.cardID,
          });
          RS.promotionsView.searchdata = _.extend(RS.promotionsView.searchdata, {
            currency:currency,
            propertyID:0,
            categoryID:0,
            cardID:RS.cardID,
          });
          if(!this.refresh){
            this.categoryView.fetch(this.catdata);
            if(window.app.viewName == 'promotion'){
                window.app.views[window.app.viewName].changeCard(RS.cardID);
            }
            else{
               this.searchHome();
               window.router.navigate('#!',{trigger:false});
            }

          }
          else if(this.refresh && !this.isSearch && !this.isCat){
              this.refresh = false;
              this.categoryView.fetch(this.catdata);
              this.searchHome();
          }
          else{
            if(this.isSearch){
               this.catdata.search = this.searchInfo;
               this.searchdata.search = this.searchInfo;
               //this.categoryView.fetch(this.catdata);
               $('#left-menu .keyword-container input[type=search]').val(this.searchInfo).siblings('span').children('#search-keyword-btn').trigger('click');
            }
            else{
               this.categoryView.fetch(this.catdata);
            }
          }
        },
        getSelect:function(e,key){
            var $target = $(e.currentTarget);
            var name = $target.find('a').html();
            var value = $target.attr('data-id');
            $target.addClass('active').siblings('.active').not(this).removeClass('active');
            var $view = $target.closest('.dropdown').siblings('button[data-toggle="dropdown"]').find('span.name');
            
            if(key == 'currency'){
              $view.html($target.html());
              RS.currency = value;
              if(window.app.viewName != 'promotion'){
                  RS.featPromoView.render();
              }
              if(RS.first_in == false){
                 this.changeQuery('currency', value);
              }
            //  RS.favourites.fetch();
            }
            else{
              $view.html(name);  
            }
            this.catdata[key] = value;
     
            this.searchdata[key] = value;
            this.searchdata.categoryID = 0;
            RS.promotionsView.searchdata[key] = value;
            if(!(this.isSearch&&this.refresh)){
                this.categoryView.fetch(this.catdata);
            }
            if(!this.refresh){
                if(key == 'currency' && window.app.viewName == 'promotion'){
                    if(!window.app.views[window.app.viewName].changeCurrency(value)){
                        this.searchHome();
                        window.router.navigate('#!',{trigger:false});
                    }
                }
                else{
                   this.searchHome();
                   window.router.navigate('#!',{trigger:false});
                }
            }
            else if(this.refresh && !this.isSearch && !this.isCat){
              this.searchHome('fresh');
              this.refresh = false;
            }
            else{
              if(this.isSearch){
                 this.catdata.search = this.searchInfo;
                 this.searchdata.search = this.searchInfo;
                 //this.categoryView.fetch(this.catdata);
                 $('#left-menu .keyword-container input[type=search]').val(this.searchInfo).siblings('span').children('#search-keyword-btn').trigger('click');
              }
            }
        },
        getCategory: function (e) {
            var $target = $(e.currentTarget);
            var cat = $target.attr('data-name');
            $target.addClass('active').siblings('.active').not(this).removeClass('active');
            var catID = $target.attr('data-id');
            this.searchdata.categoryID = $target.attr('data-id');
            if(!this.refresh){
                window.router.navigate('#!category/'+ cat, { trigger: false });
				var old_cat = cat.split('-');
				_.each(old_cat,function(v,i){ 
				  	if(v != 'and'){
						old_cat[i] = v[0].toUpperCase() + v.slice(1);
						if(old_cat[i+1] && old_cat[i+1] != 'and' ){
            			    old_cat[i] += ',';
            		    }
					}
				});
				var new_cat = old_cat.join(' ');
				console.log('new_cat');
				console.log(new_cat);
				var cat_title = new_cat + RS.metacatdefault.title;
				var cat_desc = RS.metacatdefault.desc_1 + new_cat +RS.metacatdefault.desc_2;
				$('meta[name="title"]').prop('content',cat_title);
				$('meta[property="og:title"]').prop('content',cat_title);
				$('meta[name="description"]').prop('content',cat_desc);
				$('meta[property="og:description"]').prop('content',cat_desc); 
                this.searchHome();
            }
            else if(this.isCat){
              this.refresh = false;
              this.isCat = false;
              this.searchHome();
            }
            
        },
        getSort: function (e) {
          e.preventDefault();
          var $target = $(e.currentTarget);
          var name = $target.html();
          $target.addClass('active').siblings('.active').not(this).removeClass('active');
          $target.closest('.dropdown').siblings('button[data-toggle="dropdown"]').find('.name').html(name);
          var sortIndex = Number($target.attr('data-id'));
          this.changeQuery('sortby', sortIndex);
          _.extend(this.searchdata, this.defaults.order[sortIndex],{page:1});
          this.searchHome();
        },
        changeQuery: function(key, value){
          var params = purl().data.param.query || {};
          if(!params[key]||params[key] != value){
              params[key] = value;
              var searchUrl = $.param(params);
              searchUrl = searchUrl ? '?' + searchUrl : '';
              var url = window.location.origin + window.location.pathname + searchUrl + window.location.hash;
              window.history.pushState({'promotions':url}, 'promotions', url);        
          }
     
        },
        searchHome: function (fresh) {   
            RS.promotionsView.searchdata = _.extend({},RS.promotionsView.searchdata,this.searchdata);
            var hash = window.location.hash.replace('#!','');
            if(fresh && ['', 'home'].indexOf(hash)<0){
                return;
            }
            if (!$('#home-container').is(':visible')) {
                window.router.navigate('#!home', { trigger: true });
            }
            else{
                RS.promotionsView.fetch();
            }
            
            this.setSearchInfo();
           
            if((ResponsiveBootstrapToolkit.is('xs') || ResponsiveBootstrapToolkit.is('sm')) && $('.slide-effect').attr('data-action')=='active-left'){
                $('#navbar-header-mobile').find('.btn-left').trigger('click');              
            }
        },
        setSearchInfo:function(){
          var $searchTarget = $('#home-container').find('.searchinfo-view .searchinfo-container');
            if(this.searchdata.search){
                $searchTarget.find('span.keyword').text(this.searchdata.search);
                $searchTarget.show();
            }
            else{
                $searchTarget.hide();
                $('#search-keyword-input').val('');
            }
        }
    });

    RS.Views.CategoryView = RS.Views.BaseView.extend({
        initialize: function (options) {
            this.refresh = true;
            this.model.on('sync', function () {
                this.render();
                if (!ResponsiveBootstrapToolkit.is('xs') && !ResponsiveBootstrapToolkit.is('sm') && this.refresh) { 
                   $('#main-container').css('min-height',($('.category-container').height()+$('.category-container').offset().top+30));
                   this.refresh = false;
                }
            }, this);
            var hash = window.location.hash;
            if(hash.indexOf('#!category')>=0){
              var str = hash.slice(hash.indexOf('/')+1);
							if(str.match('%')){
								this.category = this.getCategoryName(decodeURIComponent(str));
							}else{
								this.category = str.trim();
							}
            }
        },
        events: {
        },
				getCategoryName:function(str){
					var catName = str.toLowerCase().trim().replace(/&/g,'and').replace(/\W+/g,'-').replace(/^-+/g,'').replace(/-+$/g,'');
					return catName;
				},
        render: function () {
            var catList = this.model.toJSON();
			var _this = this;
            var categories = _.map(catList, function (category) {
                return '<li data-id="'+ category.ID +'" data-name="'+ _this.getCategoryName(category.EnglishName) +'"><i class="icon-general icon-category icon-pic icon-cat-'+ category.ID+'"></i>' + '<span class="name">' + category.Name + '</span>' + '  (' + category.Count + ')</li>';
            });
            //call first time
            if(this.category && categories.length>0){
              if(window.router)
			    window.router.navigate('#!category/'+ this.category, { trigger: false });
              //check whether has that cat
              var tempCat= this.getCategoryName(catList[0].EnglishName);
              var catLink= this.category;
              $.each(catList,function(i,v){
                if(catLink == _this.getCategoryName(v.EnglishName)){
                  tempCat = catLink;
                  return false;
                }
              });
              this.category ='';
              this.$el.find('ul.cat-list').html(categories).find('li[data-name="'+ tempCat +'"]').addClass('active').trigger('click'); 
            }
            else{
              this.$el.find('ul.cat-list').html(categories).find('li:first-child').addClass('active');
            }     
        },

        fetch: function (catdata) {
            this.model.clear({ silence: true });
            this.model.fetch({ data: catdata });
        },
        show: function () {
            this.$el.show();
        },
        hide: function () {
            this.$el.hide();
        },
    });
    RS.Views.PropertyView = RS.Views.BaseView.extend({
        defaults:{
               property:{
                   btn: '#select-property-btn',
                   menu: '#select-menu-property',
                   select: 'select[name=select-property]',
               },
               currency:{
                   btn: '#select-currency-btn',
                   menu: '#select-menu-currency',
                   select: 'select[name=select-currency]',
               },
        },
     
        initialize: function (options) {
            this.options = _.extend(this.defaults,this.options);
        },
        events: {
        },
        render: function () {
            var propertyList = [],
                propertyDict = {},
                currencyList = [],
                currencyCodeList = [],
                dfCurrenyCode; 
                //dfPropertyID,dfPropertyName,
             
             _.each(this.collection.toJSON(), function (model) {
                propertyList.push({
                    ID: model.ID,
										//PropertyCode:model.PropertyCode,
                    PropertyName: model.PropertyName,
                });
                propertyDict[model.PropertyCode] = model.PropertyName;
                if (_.indexOf(currencyCodeList, model.CurrencyCode) < 0) {
                    currencyList.push({
                        CurrencyCode: model.CurrencyCode,
                        Country: model.Country,
                    });
                    currencyCodeList.push(model.CurrencyCode);
                }
                //for test
//                if(model.ID == 2){
//                  model.Defaulted = true;
//                }
                if(model.Defaulted == true){
                  //dfPropertyID = model.ID;
                  //dfPropertyName = model.PropertyName;
                  dfCurrenyCode = model.CurrencyCode;
                }
            });
            var propertyListDesk = _.map(propertyList, function (property) {
                return '<li data-id="' + property.ID + '"><a>' + property.PropertyName  +'</a></li>';
            });
            var propertyListMobile = _.map(propertyList, function (property) {
                return '<option value="' + property.ID + '">' + property.PropertyName  +'</option>';
            });
            var currencyListDesk = _.map(currencyList, function (currency) {
                return '<li data-id="' + currency.CurrencyCode + '"><i class="icon-currency">'+ currency.CurrencyCode+'</i></li>';
            });
            var currencyListMobile = _.map(currencyList, function (currency) {
                return '<option value="' + currency.CurrencyCode + '">' + currency.CurrencyCode + '</option>';
            });
            this.$el.find(this.options.property.select).append(propertyListMobile);
            this.$el.find(this.options.currency.select).append(currencyListMobile);
            this.$el.find(this.options.property.menu).children('.dropdown-menu').append(propertyListDesk);
			
            if(RS.login && RS.cardID){
                this.$el.find(this.options.currency.menu).children('.dropdown-menu').append(currencyListDesk);
            }else{
							  if(RS.property_default && propertyDict[RS.property_default]){
							  	this.$el.find(this.options.property.btn).find('.name').html(propertyDict[RS.property_default]);
							  }
                this.$el.find(this.options.currency.menu).children('.dropdown-menu').append(currencyListDesk)
                .find(dfCurrenyCode?'li[data-id="'+ dfCurrenyCode +'"]':'li[data-id="'+ RS.currency_default +'"]').trigger('click');
            }

            //this.$el.show();
        },
        initCurrency: function(isRefresh){
            var params = purl().data.param.query || {};
            if( 'currency' in params && !isRefresh){
                params = _.omit(params, 'currency');
                var searchUrl = $.param(params);
                searchUrl = searchUrl ? '?' + searchUrl : '';
                var hash = window.location.hash;
                var url = window.location.origin + window.location.pathname + searchUrl + hash;
                
                var pushStateParam = {};
                pushStateParam[hash] = url;
                window.history.pushState(pushStateParam, hash, url);        
            }     

            RS.currency_default = isRefresh && 'currency' in params? params.currency: RS.Geo.get('Currency');
            RS.currency = RS.currency_default;
            RS.promotionsView.searchdata.currency = RS.currency_default; 
            this.$el.find(this.options.currency.menu).children('.dropdown-menu').find('li.active').removeClass('active');
            var $default_currency_li = this.$el.find(this.options.currency.menu).children('.dropdown-menu').find('li[data-id="'+ RS.currency_default +'"]');
            $default_currency_li.addClass('active');
            this.$el.find(this.options.currency.btn).find('.name').html($default_currency_li.find('i').text());

            
             
        },
        show: function () {
            this.$el.show();
            //this.collection.fetch();
        },
        hide: function () {
            this.$el.hide();
        },
    });

     RS.Views.CardsView = RS.Views.BaseView.extend({
        defaults:{
               card:{
                   btn: '#select-card-btn',
                   menu: '#select-menu-card',
                   select: 'select[name=select-card]',
               },
        },
     
        initialize: function (options) {
            this.options = _.extend(this.defaults,this.options);
            
        },
        events: {
        },
        render: function () {
            var cardsList= [];
            _.each(RS.cards.toJSON(),function(card){
              if(card.Status == 'Active'){
                cardsList.push('<li data-id="'+ card.ID +'" data-currency="'+card.CurrencyCode+'"><i class="icon-card icon-card_'+ card.CardType.toLowerCase() +'"></i><a>' + card.PropertyName +'</a></li>');
              }
            });
            
            this.$el.find('.card-list').html(cardsList).find('li:first-child').trigger('click');
   
            
           /* var cardsListDesk = [], cardsListMobile = [];
            _.each(RS.cards.toJSON(),function(card){
              if(card.Status == 'Active'){
                cardsListDesk.push('<li data-id="'+ card.ID +'" data-currency="'+card.CurrencyCode+'"><i class="icon-card icon-card_'+ card.CardType.toLowerCase() +'"></i><a>' + card.PropertyName +'</a></li>');
                cardsListMobile.push('<option value="' + card.ID + '">' + card.PropertyName + '</option>');
              }
            });
            this.$el.find(this.options.card.menu).children('.dropdown-menu').append(cardsListDesk).find('li:first-child').trigger('click');
            this.$el.find(this.options.card.select).append(cardsListMobile);*/
        },
        show: function () {
            this.render();
            this.$el.show();
        },
        hide: function () {
            this.$el.hide();
        },
    });
})(window.Redso);