(function(RS){
  'use strict';

  RS.Collections.Countries = RS.Collections.GRA.extend({
    model: RS.Models.Country,
    
    //url: '/apis/get_brands',
    url: RS.apiUrl+'/IData/GetCountries',
    
    parse: function(resp, options){
      if (resp)
        return resp;
    },

  });

  RS.Collections.States = RS.Collections.GRA.extend({
    model: RS.Models.State,
    
    //url: '/apis/get_brands',
    url: RS.apiUrl+'/IData/GetStateList',
    
    parse: function(resp, options){
      if (resp)
        return resp;
    },

  });

})(window.Redso);