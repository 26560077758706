(function(RS){
  "use strict";

  RS.Models.Redemption = RS.Models.GRA.extend({
    sync: function(method, model, options){
      if (method=='save'){
        options.url = RS.apiUrl+'/IRedemptions/'; 
      } else if (method=='create'){
        options.url = RS.apiUrl+'/IRedemptions/'; 
      } else {
        options.url = '';
      }
      return RS.Models.GRA.prototype.sync(method, model, options);
    },

    parse: function(resp, options){
      if (resp.RedemptionDateTime) {
        resp.RedemptionDateTime = moment(resp.RedemptionDateTime);
      }
      if (resp.ConsumedDateTime) {
        resp.ConsumedDateTime = moment(resp.ConsumedDateTime);
      }
      if (resp.ValidFrom) {
        resp.ValidFrom = moment(resp.ValidFrom);
      }
      if (resp.ValidTo) {
        resp.ValidTo = moment(resp.ValidTo);
      }
      return resp;
    }
  });

  RS.Models.RedemptionPDFLink = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IRedemptions/GenerateVoucher?redemptionID='+options.data.redemptionID; 
      options.type = 'PUT';
      return RS.Models.GRA.prototype.sync(method, model, options);
    }
  });

  RS.Models.RedemptionTaxInvoice = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IRedemptions/GenerateTaxInvoice?redemptionID='+options.data.redemptionID; 
      options.type = 'PUT';
      return RS.Models.GRA.prototype.sync(method, model, options);
    }
  });

  RS.Models.Redeem = RS.Models.GRA.extend({
	// create: promoLineID, cardID, AddressID
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IPromos/Redeem';
      options.type = 'POST';
      return RS.Models.GRA.prototype.sync(method, model, options);
    }
  });

  RS.Models.UseVoucher = RS.Models.GRA.extend({
    sync: function(method, model, options){
      options.url = RS.apiUrl+'/IRedemptions/ConsumedVoucher?'+$.param(options.data); 
      options.type = 'POST';
      return RS.Models.GRA.prototype.sync(method, model, options);
    }
  });
  RS.Models.Coupon = RS.Models.GRA.extend({
      url: RS.apiUrl + '/IMisc/ValidateCoupon',
  });

})(window.Redso);
